define('ember-hiding-menu/components/hiding-menu', ['exports', 'ember', 'ember-hiding-menu/templates/components/hiding-menu'], function (exports, _ember, _emberHidingMenuTemplatesComponentsHidingMenu) {
  var run = _ember['default'].run;
  var $ = _ember['default'].$;
  exports['default'] = _ember['default'].Component.extend({
    layout: _emberHidingMenuTemplatesComponentsHidingMenu['default'],

    tagName: 'nav',
    classNames: ['hiding-menu'],

    throttleTime: 200,
    tolerance: 0,
    topTolerance: 50,
    'class': null,

    classNameBindings: ['isHidden:hidden', 'class'],

    didInsertElement: function didInsertElement() {
      this.setupScrollMenuToggle();
    },

    willDestroyElement: function willDestroyElement() {
      $(window).off('scroll.' + this.get('elementId'));
    },

    setupScrollMenuToggle: function setupScrollMenuToggle() {
      var _this = this;

      var $document = $(document);
      var $el = $(this.element);
      var $menu = $el;
      this.set('menuHeight', $menu.outerHeight());

      $(window).on('scroll.' + this.get('elementId'), function (event) {
        run.throttle(_this, function () {
          return _this.onScroll(event, $document);
        }, _this.get('throttleTime'));
      });
    },

    onScroll: function onScroll(event, $document) {
      if (!this.get('isDestroyed')) {

        var newScrollTop = $document.scrollTop();
        if (newScrollTop > this.get('bodyScrollTop')) {
          this.hideMenu(newScrollTop);
        } else {
          this.showMenu();
        }

        this.set('bodyScrollTop', newScrollTop);
      }
    },

    hideMenu: function hideMenu(newScrollTop) {
      if (!this.get('isHidden') && newScrollTop > this.get('menuHeight') + this.get('topTolerance')) {
        this.set('isHidden', true);
      }
    },

    showMenu: function showMenu() {
      this.set('isHidden', false);
    }
  });
});