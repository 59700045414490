define('ember-countries/utils/states-properties', ['exports', 'ember', 'ember-countries/utils/helpers', 'ember-countries/utils/countries-properties', 'ember-countries/utils/states-lists'], function (exports, _ember, _emberCountriesUtilsHelpers, _emberCountriesUtilsCountriesProperties, _emberCountriesUtilsStatesLists) {
  exports.getState = getState;
  exports.getStatesForCountry = getStatesForCountry;
  exports.isCustomsDeclarationRequiredInUS = isCustomsDeclarationRequiredInUS;
  exports.countryContainsState = countryContainsState;
  var A = _ember['default'].A;
  var isNone = _ember['default'].isNone;

  var indexedByIso2 = undefined;

  function getState(country, value) {
    var valueFormat = (0, _emberCountriesUtilsHelpers.getFormat)(value);
    if (valueFormat === null || valueFormat !== 'iso2') {
      return;
    }
    var STATES_LIST = getStatesForCountry(country);
    if (isNone(STATES_LIST)) {
      return;
    }

    var listIndexed = _getStatesListIndexed(STATES_LIST, valueFormat);
    var selectedState = listIndexed[value.toUpperCase()];

    if (!isNone(selectedState)) {
      return selectedState;
    }
  }

  function getStatesForCountry(value) {
    if (!(0, _emberCountriesUtilsCountriesProperties.isCountryWithState)(value)) {
      return;
    }
    var country = (0, _emberCountriesUtilsCountriesProperties.getCountry)(value);

    return _emberCountriesUtilsStatesLists.STATES_BY_COUNTRIES[country.iso2];
  }

  function isCustomsDeclarationRequiredInUS(stateFrom, stateTo) {
    if (stateFrom === stateTo) {
      return false;
    }

    return A([stateFrom, stateTo]).any(function (state) {
      return _emberCountriesUtilsStatesLists.US_REQUIRING_CUSTOM_DECLARATION_STATES_LIST.indexOf(state) !== -1;
    });
  }

  function countryContainsState(country, state) {
    var STATES_LIST = getStatesForCountry(country);
    if (isNone(STATES_LIST)) {
      return false;
    }
    return STATES_LIST.mapBy('iso2').indexOf(state) !== -1;
  }

  function _getStatesListIndexed(LIST, code) {
    var index = {};
    if (code === 'iso2') {
      index = (0, _emberCountriesUtilsHelpers.buildIndex)(LIST, code, indexedByIso2);
      indexedByIso2 = index;
    }
    return index;
  }
});