define('ember-multi-input/components/multi-input', ['exports', 'ember', 'ember-multi-input/templates/components/multi-input'], function (exports, _ember, _emberMultiInputTemplatesComponentsMultiInput) {
  /* global _ */

  var _ref = _;
  var isEqual = _ref.isEqual;
  var computed = _ember['default'].computed;
  var _Ember$computed = _ember['default'].computed;
  var alias = _Ember$computed.alias;
  var and = _Ember$computed.and;
  var empty = _Ember$computed.empty;
  var notEmpty = _Ember$computed.notEmpty;
  var observer = _ember['default'].observer;
  var set = _ember['default'].set;
  var A = _ember['default'].A;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({

    classNames: ['ember-multi-input'],
    classNameBindings: ['emptyGroup:empty', '_live_error:live-error:min-error', 'hasError'],

    layout: _emberMultiInputTemplatesComponentsMultiInput['default'],
    inputs: computed(function () {
      return A([]);
    }),
    canDeleteInput: true,
    validation: false,
    type: null,
    uniqness: true,
    max: null,
    onEnter: true,
    onSpace: true,
    onAction: false,
    clearOnBlur: false,
    alwaysShowPlaceholder: false,
    mustValidate: false,
    crossOnError: true,
    valuesContainsSpaces: false,

    _current_input: '',
    _prev_serach_length: alias('_current_input.length'),
    _inputsNumber: alias('inputs.length'),
    emptyGroup: empty('inputs'),
    showErrorCross: and('crossOnError', '_error.full_message'),
    hasError: notEmpty('_error.full_message'),

    inputGroup: computed('inputs.[]', {
      get: function get() {
        return this.get('inputs').mapBy('value');
      },
      set: function set(_, new_values, old_values) {
        var _this = this;

        if (new_values && !isEqual(new_values, old_values)) {
          new_values.forEach(function (value) {
            _this.get('inputs').addObject({ id: Symbol(), value: value });
          });
        }
        return this.get('inputs').mapBy('value');
      }
    }),

    inputPlaceholder: computed('placeholder', '_inputsNumber', 'alwaysShowPlaceholder', function () {
      return this.get('alwaysShowPlaceholder') ? this.get('placeholder') : this.get('_inputsNumber') === 0 ? this.get('placeholder') : '';
    }),

    i18nObserver: observer('i18n.locale', function () {
      this.get('i18n.locale');
      var error = this.get('_error');
      if (error) {
        set(error, 'full_message', this._get_message_error(error.label));
      }
    }),

    /* observes the input value and display error if there is one */
    inputObserver: observer('_error.value', '_current_input', function () {
      var current_input = this.get('_current_input');
      if (current_input === "" || !current_input) {
        this.set('_error', null);
      }
      var error_value = this.get('_error.value');
      if (error_value) {
        this.set('_live_error', error_value === current_input);
      } else {
        this.set('_live_error', false);
      }
    }),

    click: function click() {
      var $input = this.$('input');
      if ($input) {
        $input.focus();
      }
    },

    _get_message_error: function _get_message_error(label) {
      var error_path = this.get('errorPath'),
          i18n = this.get('i18n');
      var full_message = undefined;
      if (i18n) {
        full_message = this.get('i18n').t('' + (error_path ? error_path + '.' : '') + label);
      } else {
        switch (label) {
          case 'many_errors':
            full_message = 'there are many errors on one or more inputs';
            break;
          case 'already_taken':
            full_message = 'this value is already used';
            break;
          case 'invalid_format':
            full_message = 'your input format is not valid';
            break;
          default:
            full_message = 'unkonwn error';
            break;
        }
      }
      return full_message;
    },

    _display_error: function _display_error(label, value) {
      this.set('_live_error', true);
      var full_message = this._get_message_error(label);
      this.set('_error', {
        label: label,
        value: value,
        full_message: full_message
      });
    },

    _set_input_value: function _set_input_value(value) {
      this.$('input')[0].value = value;
      this.set('_current_input', value);
    },

    _extract_values_from_input: function _extract_values_from_input() {
      var value = this.$('input')[0].value.trim();
      if (!this.get('valuesContainsSpaces')) {
        return value.split(' ').filter(function (a) {
          return a.trim().length > 0;
        });
      }
      return value.length > 0 ? [value] : [];
    },

    _try_set_new_inputs: function _try_set_new_inputs() {
      var _this2 = this;

      var fromPaste = arguments.length <= 0 || arguments[0] === undefined ? false : arguments[0];

      var values = this._extract_values_from_input();
      if (fromPaste && values.length <= 1) {
        return;
      }
      var errors = values.map(function (input) {
        if (_this2.get('mustValidate') && _this2.get('validation')(input) || _this2.get('uniqness') && (_this2.get('inputs') || A([])).filterBy('value', input)[0]) {
          return input;
        } else {
          _this2.get('inputs').addObject({ id: Symbol(), value: input });
          return null;
        }
      }).filter(function (a) {
        return a;
      });
      var error_input = errors.join(" ");
      if (error_input !== values.join(" ")) {
        this._set_input_value(error_input);
      }
      if (errors.length > 0) {
        this._display_error(errors.length > 1 ? 'many_errors' : this.get('validation') && this.get('validation')(errors[0]) || 'already_taken', error_input);
      } else {
        this.set('_error', null);
        this._set_input_value("");
      }
    },

    _edit_input: function _edit_input(id) {
      if (id) {
        var to_remove = this.get('inputs').findBy('id', id);
        if (to_remove) {
          this._set_input_value(to_remove.value);
          this.send('deleteInput', to_remove.id);
        }
      }
    },

    actions: {
      onInput: function onInput(onInputEvent) {
        var prev_length = this.get('_prev_serach_length');
        if ((onInputEvent.srcElement.value || '').length > prev_length + 1) {
          this.set('_current_input', onInputEvent.srcElement.value);
          this._try_set_new_inputs(true);
        } else {
          this.set('_current_input', onInputEvent.srcElement.value);
        }
      },

      deleteInput: function deleteInput(id) {
        var to_remove = this.get('inputs').findBy('id', id);
        this.get('inputs').removeObject(to_remove);
      },

      onBlur: function onBlur() {
        this._try_set_new_inputs();
      },

      onKeydown: function onKeydown(event) {
        if (this.get('onSpace') && event.keyCode === 32 || this.get('onEnter') && event.keyCode === 13) {
          this._try_set_new_inputs();
          return false;
        }
        if (event.keyCode === 8 && (this.get('_current_input.length') || 0) === 0) {
          this._edit_input(this.get('inputs.lastObject.id'));
        }
      },

      editInput: function editInput(id) {
        this._edit_input(id);
        this.$('input')[0].focus();
      }

    }
  });
});