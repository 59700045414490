define("ember-key-manager/utils/key-codes", ["exports"], function (exports) {
  var all = {
    helpFF: 8,
    backspace: 8,
    tab: 9,
    numpadEqual: 12,
    numLockMac: 12,
    enter: 13,
    shift: 16,
    ctrl: 17,
    alt: 18,
    pause: 19,
    capsLock: 20,
    escape: 27,
    space: 32,
    pageUp: 33,
    pageDown: 34,
    end: 35,
    home: 36,
    arrowLeft: 37,
    arrowUp: 38,
    arrowRight: 39,
    arrowDown: 40,
    printScreenFF: 42,
    printScreenChrome: 42,
    printScreen: 44,
    insert: 45,
    "delete": 46,
    help: 46,

    0: 48,
    1: 49,
    2: 50,
    3: 51,
    4: 52,
    5: 53,
    6: 54,
    7: 55,
    8: 56,
    9: 57,

    colon: 58,
    semicolonFF: 59,
    lessThan: 60,
    equalFF: 61,
    ampersandFF: 64,

    a: 65,
    b: 66,
    c: 67,
    d: 68,
    e: 69,
    f: 70,
    g: 71,
    h: 72,
    i: 73,
    j: 74,
    k: 75,
    l: 76,
    m: 77,
    n: 78,
    o: 79,
    p: 80,
    q: 81,
    r: 82,
    s: 83,
    t: 84,
    u: 85,
    v: 86,
    w: 87,
    x: 88,
    y: 89,
    z: 90,

    meta: 91,
    metaRight: 92,
    cmdRight: 92,
    windowRight: 92,
    contextMenu: 93,

    numpad0: 96,
    numpad1: 97,
    numpad2: 98,
    numpad3: 99,
    numpad4: 100,
    numpad5: 101,
    numpad6: 102,
    numpad7: 103,
    numpad8: 104,
    numpad9: 105,
    numpadMultiply: 106,
    numpadPeriodFF: 108,
    numpadAdd: 107,
    numpadEnter: 109,
    numpadSubtract: 109,
    numpadDecimal: 110,
    numpadDivide: 111,

    f1: 112,
    f2: 113,
    f3: 114,
    f4: 115,
    f5: 116,
    f6: 117,
    f7: 118,
    f8: 119,
    f9: 120,
    f10: 121,
    f11: 122,
    f12: 123,
    f13: 124,
    printScreenMac: 124,
    f14: 125,
    scrollLockMac: 125,
    f15: 126,
    pauseMac: 126,
    f16: 127,
    f17: 128,
    f18: 129,
    f19: 130,
    f20: 131,
    f21: 132,
    f22: 133,
    f23: 134,
    f24: 135,

    numLock: 144,
    scrollLock: 145,
    caret: 160,
    exclamation: 161,
    numberSign: 163,
    dollarSign: 164,
    uGraveAccent: 165,
    pageBack: 166,
    pageForward: 167,
    closingParen: 169,
    asterisk: 170,
    mute: 173,
    minusFF: 173,
    volumeDown: 174,
    volumeUp: 175,
    playerNext: 176,
    playerPrevious: 177,
    playerStop: 178,
    playerPlay: 179,
    email: 180,
    muteFF: 181,
    volumeDownFF: 182,
    volumeUpFF: 183,
    enye: 186,
    semicolon: 186,
    equal: 187,
    comma: 188,
    minus: 189,
    period: 190,
    slash: 191,
    backquote: 192,
    graveAccent: 192,
    degreeSign: 193,
    numpadComma: 194,
    bracketLeft: 219,
    backslash: 220,
    bracketRight: 221,
    quote: 222,
    cmdFF: 224,
    altGr: 225,
    composeGnome: 230,
    cedilla: 231,
    touchpadToggle: 255
  };

  exports["default"] = all;
});