define('ember-countries/utils/countries-properties', ['exports', 'ember', 'ember-countries/utils/countries-lists', 'ember-countries/utils/helpers'], function (exports, _ember, _emberCountriesUtilsCountriesLists, _emberCountriesUtilsHelpers) {
  exports.getCountry = getCountry;
  exports.isCountryWithoutZip = isCountryWithoutZip;
  exports.isCountryWithState = isCountryWithState;
  var isNone = _ember['default'].isNone;

  var indexedByIso2 = undefined;
  var indexedByIso3 = undefined;
  var indexedByIsoNumeric = undefined;

  function getCountry(value) {
    var valueFormat = (0, _emberCountriesUtilsHelpers.getFormat)(value);
    if (valueFormat === null) {
      return;
    }

    var listIndexed = _getCountriesListIndexed(valueFormat);
    var selectedCountry = listIndexed[value.toUpperCase()];

    if (!isNone(selectedCountry)) {
      return selectedCountry;
    }
  }

  function isCountryWithoutZip(value) {
    var country = getCountry(value);
    if (isNone(country)) {
      return false;
    }

    // return true if the country has not a required ZIP code
    return _emberCountriesUtilsCountriesLists.COUNTRIES_WITHOUT_ZIP_LIST.indexOf(country.iso2) !== -1;
  }

  function isCountryWithState(value) {
    var country = getCountry(value);
    if (isNone(country)) {
      return false;
    }

    // return true if the country has a required State.
    return _emberCountriesUtilsCountriesLists.COUNTRIES_WITH_STATES_LIST.indexOf(country.iso2) !== -1;
  }

  function _getCountriesListIndexed(code) {
    var index = {};
    if (code === 'iso2') {
      index = (0, _emberCountriesUtilsHelpers.buildIndex)(_emberCountriesUtilsCountriesLists.COUNTRIES_LIST, code, indexedByIso2);
      indexedByIso2 = index;
    } else if (code === 'iso3') {
      index = (0, _emberCountriesUtilsHelpers.buildIndex)(_emberCountriesUtilsCountriesLists.COUNTRIES_LIST, code, indexedByIso3);
      indexedByIso3 = index;
    } else if (code === 'isoNumeric') {
      index = (0, _emberCountriesUtilsHelpers.buildIndex)(_emberCountriesUtilsCountriesLists.COUNTRIES_LIST, code, indexedByIsoNumeric);
      indexedByIsoNumeric = index;
    }
    return index;
  }
});