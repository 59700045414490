define('ember-cli-scrollbar/mixins/perfect-scrollbar', ['exports', 'ember'], function (exports, _ember) {
  var typeOf = _ember['default'].typeOf;
  var camelize = _ember['default'].String.camelize;

  var events = ['ps-scroll-y', 'ps-scroll-x', 'ps-scroll-up', 'ps-scroll-down', 'ps-scroll-left', 'ps-scroll-right', 'ps-y-reach-start', 'ps-y-reach-end', 'ps-x-reach-start', 'ps-x-reach-end'];

  exports['default'] = _ember['default'].Mixin.create({
    perfectScrollbarOptions: {},

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      PerfectScrollbar.initialize(this.element, this.get('perfectScrollbarOptions'));

      var self = this.$();
      events.map(function (event) {
        self.on(event, _this._registerAction(camelize(event)));
      });
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var self = this.$();
      events.map(function (event) {
        self.off(event);
      });

      PerfectScrollbar.destroy(this.element);
    },

    psUpdate: function psUpdate() {
      PerfectScrollbar.update(this.element);
    },

    _registerAction: function _registerAction(action) {
      return _ember['default'].run.bind(this, function () {
        var args = Array.prototype.slice.call(arguments);
        args.unshift(action);

        if (typeOf(this[action]) === 'function') {
          this[action].apply(this, args);
        }
        this.sendAction.apply(this, args);
      });
    }
  });
});