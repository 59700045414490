define('ember-data-model-fragments/ext', ['exports', 'ember-data/store', 'ember-data/model', 'ember-data/-private/system/model/internal-model', 'ember-data/-private/system/store/container-instance-cache', 'ember-data/serializers/json', 'ember-data-model-fragments/states', 'ember-data-model-fragments/fragment', 'ember-data-model-fragments/array/fragment'], function (exports, _store, _model, _internalModel, _containerInstanceCache, _json, _states, _fragment, _fragment2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.JSONSerializer = exports.Model = exports.Store = undefined;


  /**
    @module ember-data-model-fragments
  */

  var keys = Object.keys || Ember.keys;
  var create = Object.create || Ember.create;
  var getOwner = Ember.getOwner;

  var InternalModelPrototype = _internalModel.default.prototype;
  var internalModelExpectsModelName = InternalModelPrototype.hasOwnProperty('modelClass');

  /**
    @class Store
    @namespace DS
  */
  _store.default.reopen({
    createFragment: function createFragment(modelName, props) {
      Ember.assert('The \'' + modelName + '\' model must be a subclass of MF.Fragment', this.isFragment(modelName));

      var internalModel = void 0;
      if (internalModelExpectsModelName) {
        internalModel = new _internalModel.default(modelName, null, this, getOwner(this).container);
      } else {
        // BACKWARDS_COMPAT: <= Ember 2.11 the `InternalModel` expected the class,
        // rather than the modelName, as it's first argument.
        var type = this.modelFor(modelName);
        internalModel = new _internalModel.default(type, null, this, getOwner(this).container);
      }

      // Re-wire the internal model to use the fragment state machine
      internalModel.currentState = _states.default.empty;

      internalModel._name = null;
      internalModel._owner = null;

      internalModel.loadedData();

      var fragment = internalModel.getRecord();

      if (props) {
        fragment.setProperties(props);
      }

      // invoke the ready callback ( to mimic DS.Model behaviour )
      fragment.ready();

      // Add brand to reduce usages of `instanceof`
      fragment._isFragment = true;

      return fragment;
    },
    isFragment: function isFragment(modelName) {
      var type = this.modelFor(modelName);
      return _fragment.default.detect(type);
    }
  });

  /**
    @class Model
    @namespace DS
    */
  _model.default.reopen({
    changedAttributes: function changedAttributes() {
      var diffData = this._super.apply(this, arguments);
      var internalModel = (0, _fragment.internalModelFor)(this);

      keys(internalModel._fragments).forEach(function (name) {
        // An actual diff of the fragment or fragment array is outside the scope
        // of this method, so just indicate that there is a change instead
        if (name in internalModel._attributes) {
          diffData[name] = true;
        }
      });

      return diffData;
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      var internalModel = (0, _fragment.internalModelFor)(this);
      var key = void 0,
          fragment = void 0;

      // destroy the current state
      for (key in internalModel._fragments) {
        fragment = internalModel._fragments[key];
        if (fragment) {
          fragment.destroy();
          delete internalModel._fragments[key];
        }
      }

      // destroy the original state
      for (key in internalModel._data) {
        fragment = internalModel._data[key];
        if (fragment instanceof _fragment.default || fragment instanceof _fragment2.default) {
          fragment.destroy();
          delete internalModel._data[key];
        }
      }
    }
  });

  // Replace a method on an object with a new one that calls the original and then
  // invokes a function with the result
  function decorateMethod(obj, name, fn) {
    var originalFn = obj[name];

    obj[name] = function () {
      var value = originalFn.apply(this, arguments);

      return fn.call(this, value, arguments);
    };
  }

  /**
    Override parent method to snapshot fragment attributes before they are
    passed to the `DS.Model#serialize`.
  
    @method _createSnapshot
    @private
  */
  decorateMethod(InternalModelPrototype, 'createSnapshot', function createFragmentSnapshot(snapshot) {
    var attrs = snapshot._attributes;

    keys(attrs).forEach(function (key) {
      var attr = attrs[key];

      // If the attribute has a `_createSnapshot` method, invoke it before the
      // snapshot gets passed to the serializer
      if (attr && typeof attr._createSnapshot === 'function') {
        attrs[key] = attr._createSnapshot();
      }
    });

    return snapshot;
  });

  /**
    If the model `hasDirtyAttributes` this function will discard any unsaved
    changes, recursively doing the same for all fragment properties.
  
    Example
  
    ```javascript
    record.get('name'); // 'Untitled Document'
    record.set('name', 'Doc 1');
    record.get('name'); // 'Doc 1'
    record.rollbackAttributes();
    record.get('name'); // 'Untitled Document'
    ```
  
    @method rollbackAttributes
  */
  decorateMethod(InternalModelPrototype, 'rollbackAttributes', function rollbackFragments() {
    for (var key in this._fragments) {
      if (this._fragments[key]) {
        this._fragments[key].rollbackAttributes();
      }
    }
  });

  /**
    Before saving a record, its attributes must be moved to in-flight, which must
    happen for all fragments as well
  
    @method flushChangedAttributes
  */
  decorateMethod(InternalModelPrototype, 'flushChangedAttributes', function flushChangedAttributesFragments() {
    var fragment = void 0;

    // Notify fragments that the record was committed
    for (var key in this._fragments) {
      fragment = this._fragments[key];
      if (fragment) {
        fragment._flushChangedAttributes();
      }
    }
  });

  /**
    If the adapter did not return a hash in response to a commit,
    merge the changed attributes and relationships into the existing
    saved data and notify all fragments of the commit.
  
    @method adapterDidCommit
  */
  decorateMethod(InternalModelPrototype, 'adapterDidCommit', function adapterDidCommitFragments(returnValue, args) {
    var attributes = args[0] && args[0].attributes || create(null);
    var fragment = void 0;

    // Notify fragments that the record was committed
    for (var key in this._fragments) {
      fragment = this._fragments[key];
      if (fragment) {
        fragment._adapterDidCommit(attributes[key]);
      }
    }
  });

  decorateMethod(InternalModelPrototype, 'adapterDidError', function adapterDidErrorFragments(returnValue, args) {
    var error = args[0] || create(null);
    var fragment = void 0;

    // Notify fragments that the record was committed
    for (var key in this._fragments) {
      fragment = this._fragments[key];
      if (fragment) {
        fragment._adapterDidError(error);
      }
    }
  });

  /**
    @class JSONSerializer
    @namespace DS
  */
  _json.default.reopen({
    transformFor: function transformFor(attributeType) {
      if (attributeType.indexOf('-mf-') === 0) {
        return getFragmentTransform(getOwner(this), this.store, attributeType);
      }

      return this._super.apply(this, arguments);
    }
  });

  // Retrieve or create a transform for the specific fragment type
  function getFragmentTransform(owner, store, attributeType) {
    var containerKey = 'transform:' + attributeType;
    var match = attributeType.match(/^-mf-(fragment|fragment-array|array)(?:\$([^$]+))?(?:\$(.+))?$/);
    var transformName = match[1];
    var transformType = match[2];
    var polymorphicTypeProp = match[3];

    if (!owner.hasRegistration(containerKey)) {
      var transformClass = void 0;
      if (owner.factoryFor) {
        transformClass = owner.factoryFor('transform:' + transformName);
        transformClass = transformClass && transformClass.class;
      } else {
        // BACKWARDS_COMPAT: <= Ember 2.11
        transformClass = owner._lookupFactory('transform:' + transformName);
      }

      owner.register(containerKey, transformClass.extend({
        store: store,
        type: transformType,
        polymorphicTypeProp: polymorphicTypeProp
      }));
    }

    return owner.lookup(containerKey);
  }

  /*
    Patch ember-data's ContainerInstanceCache. Changes fallbacks for fragments
    to use `serializer:-fragment` if registered, then uses the default serializer.
    Previously this was implemented by overriding the `serializerFor` on the store,
    however an ember-data improved their implementation, so we followed suite.
    See: https://github.com/lytics/ember-data-model-fragments/issues/224
  */
  (function patchContainerInstanceCache() {
    var ContainerInstanceCachePrototype = _containerInstanceCache.default.prototype;
    var _super = ContainerInstanceCachePrototype._fallbacksFor;
    ContainerInstanceCachePrototype._fallbacksFor = function _modelFragmentsPatchedFallbacksFor(namespace, preferredKey) {
      if (namespace === 'serializer') {
        // ember-data-v2.11.2 changed `modelFactoryFor` to return a "factory manager".
        // Test for that accordingly.
        var maybeFactory = this._store.modelFactoryFor(preferredKey);
        if (maybeFactory) {
          // BACKWARDS_COMPAT: <= Ember 2.11.2
          var model = maybeFactory.class ? maybeFactory.class : maybeFactory;
          if (_fragment.default.detect(model)) {
            return ['-fragment', '-default'];
          }
        }
      }

      return _super.apply(this, [namespace, preferredKey]);
    };
  })();

  exports.Store = _store.default;
  exports.Model = _model.default;
  exports.JSONSerializer = _json.default;
});