define("ember-file-upload/components/file-upload/template", ["exports"], function (exports) {
  "use strict";

  exports.__esModule = true;
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 4,
            "column": 0
          }
        },
        "moduleName": "ember-file-upload/components/file-upload/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("input");
        dom.setAttribute(el1, "type", "file");
        dom.setAttribute(el1, "hidden", "");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(7);
        morphs[0] = dom.createAttrMorph(element0, 'id');
        morphs[1] = dom.createAttrMorph(element0, 'accept');
        morphs[2] = dom.createAttrMorph(element0, 'capture');
        morphs[3] = dom.createAttrMorph(element0, 'multiple');
        morphs[4] = dom.createAttrMorph(element0, 'disabled');
        morphs[5] = dom.createAttrMorph(element0, 'onchange');
        morphs[6] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        return morphs;
      },
      statements: [["attribute", "id", ["get", "for", ["loc", [null, [1, 12], [1, 15]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "accept", ["get", "accept", ["loc", [null, [1, 39], [1, 45]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "capture", ["get", "capture", ["loc", [null, [1, 58], [1, 65]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "multiple", ["get", "multiple", ["loc", [null, [1, 79], [1, 87]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "disabled", ["get", "disabled", ["loc", [null, [1, 101], [1, 109]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "onchange", ["subexpr", "action", ["change"], ["value", "target.files"], ["loc", [null, [null, null], [1, 161]]], 0, 0], 0, 0, 0, 0], ["inline", "yield", [["get", "queue", ["loc", [null, [3, 8], [3, 13]]], 0, 0, 0, 0]], [], ["loc", [null, [3, 0], [3, 15]]], 0, 0]],
      locals: [],
      templates: []
    };
  }());
});