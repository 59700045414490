define('ivy-tabs/components/ivy-tabs-tablist', ['exports', 'ember', 'ivy-tabs/templates/components/ivy-tabs-tablist'], function (exports, _ember, _ivyTabsTemplatesComponentsIvyTabsTablist) {

  /**
   * @module ivy-tabs
   */

  /**
   * @class IvyTabListComponent
   * @namespace IvyTabs
   * @extends Ember.Component
   */
  exports['default'] = _ember['default'].Component.extend({
    layout: _ivyTabsTemplatesComponentsIvyTabsTablist['default'],

    attributeBindings: ['aria-multiselectable'],
    classNames: ['ivy-tabs-tablist'],

    init: function init() {
      this._super.apply(this, arguments);
      _ember['default'].run.once(this, this._registerWithTabsContainer);
    },

    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      _ember['default'].run.once(this, this._unregisterWithTabsContainer);
    },

    /**
     * Tells screenreaders that only one tab can be selected at a time.
     *
     * @property aria-multiselectable
     * @type String
     * @default 'false'
     */
    'aria-multiselectable': 'false',

    /**
     * The `role` attribute of the tab list element.
     *
     * See http://www.w3.org/TR/wai-aria/roles#tablist
     *
     * @property ariaRole
     * @type String
     * @default 'tablist'
     */
    ariaRole: 'tablist',

    /**
     * Gives focus to the selected tab.
     *
     * @method focusSelectedTab
     */
    focusSelectedTab: function focusSelectedTab() {
      this.get('selectedTab').$().focus();
    },

    /**
     * Event handler for navigating tabs via arrow keys. The left (or up) arrow
     * selects the previous tab, while the right (or down) arrow selects the next
     * tab.
     *
     * @method navigateOnKeyDown
     * @param {Event} event
     */
    navigateOnKeyDown: _ember['default'].on('keyDown', function (event) {
      switch (event.keyCode) {
        case 37: /* left */
        case 38:
          /* up */
          this.selectPreviousTab();
          break;
        case 39: /* right */
        case 40:
          /* down */
          this.selectNextTab();
          break;
        default:
          return;
      }

      event.preventDefault();
      _ember['default'].run.scheduleOnce('afterRender', this, this.focusSelectedTab);
    }),

    /**
     * Adds a tab to the `tabs` array.
     *
     * @method registerTab
     * @param {IvyTabs.IvyTabComponent} tab
     */
    registerTab: function registerTab(tab) {
      this.get('tabs').pushObject(tab);
      _ember['default'].run.once(this, this.selectTab);
    },

    /**
     * Selects the next tab in the list, if any.
     *
     * @method selectNextTab
     */
    selectNextTab: function selectNextTab() {
      var selectedTab = this.get('selectedTab');
      var index = selectedTab.get('index') + 1;
      if (index === this.get('tabs.length')) {
        index = 0;
      }
      this.selectTabByIndex(index);
    },

    /**
     * Selects the previous tab in the list, if any.
     *
     * @method selectPreviousTab
     */
    selectPreviousTab: function selectPreviousTab() {
      var selectedTab = this.get('selectedTab');
      var index = selectedTab.get('index') - 1;

      // Previous from the first tab should select the last tab.
      if (index < 0) {
        index = this.get('tabs.length') - 1;
      }
      // This would only happen if there are no tabs, so stay at 0.
      if (index < 0) {
        index = 0;
      }

      this.selectTabByIndex(index);
    },

    selectTab: function selectTab() {
      var selection = this.get('selection');
      if (_ember['default'].isNone(selection)) {
        this.selectTabByIndex(0);
      } else {
        this.selectTabByModel(selection);
      }
    },

    /**
     * The currently-selected `ivy-tabs-tab` instance.
     *
     * @property selectedTab
     * @type IvyTabs.IvyTabComponent
     */
    selectedTab: _ember['default'].computed('selection', 'tabs.@each.model', function () {
      return this.get('tabs').findBy('model', this.get('selection'));
    }),

    /**
     * Select the tab at `index`.
     *
     * @method selectTabByIndex
     * @param {Number} index
     */
    selectTabByIndex: function selectTabByIndex(index) {
      var tab = this.get('tabs').objectAt(index);

      if (!tab) {
        throw new Error('Tab index out of bounds');
      }

      tab.select();
    },

    selectTabByModel: function selectTabByModel(model) {
      var tab = this.get('tabs').findBy('model', model);

      if (!tab) {
        throw new Error('Tab could not be found by model');
      }

      tab.select();
    },

    tabs: _ember['default'].computed(function () {
      return _ember['default'].A();
    }).readOnly(),

    /**
     * The `ivy-tabs` component.
     *
     * @property tabsContainer
     * @type IvyTabs.IvyTabsComponent
     * @default null
     */
    tabsContainer: null,

    /**
     * Removes a tab from the `tabs` array.
     *
     * @method unregisterTab
     * @param {IvyTabs.IvyTabComponent} tab
     */
    unregisterTab: function unregisterTab(tab) {
      var index = tab.get('index');

      if (tab.get('isSelected') && index === 0) {
        this.selectNextTab();
      }

      this.get('tabs').removeObject(tab);
    },

    _registerWithTabsContainer: function _registerWithTabsContainer() {
      this.get('tabsContainer').registerTabList(this);
      _ember['default'].run.once(this, this.selectTab);
    },

    _unregisterWithTabsContainer: function _unregisterWithTabsContainer() {
      this.get('tabsContainer').unregisterTabList(this);
    }
  });
});