define('ui-navigator-service/services/navigator', ['exports', 'ember', 'ember-getowner-polyfill'], function (exports, _ember, _emberGetownerPolyfill) {
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;

  var navigatorService = _ember['default'].Service.extend({
    init: function init() {
      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      this._super(args);
      this.set('applicationController', (0, _emberGetownerPolyfill['default'])(this).lookup('controller:application'));
    },
    currentPath: computed.alias('applicationController.currentPath'),
    currentRouteName: computed.alias('applicationController.currentRouteName'),
    currentNode: computed('currentPath', function () {
      var chain = this.get('currentPath').split('.');
      var leaf = chain.pop();
      return leaf === 'index' ? chain.pop() : leaf;
    }),
    isIndexRoute: computed('currentPath', function () {
      var currentPath = this.get('currentPath') || '';
      return currentPath.split('.').pop() === 'index';
    }),
    primaryRoute: computed('currentPath', function () {
      var currentPath = (this.get('currentPath') || []).split('.');
      return currentPath[0] !== 'index' ? currentPath[0] : null;
    }),
    secondaryRoute: computed('currentPath', function () {
      var currentPath = (this.get('currentPath') || []).split('.');
      var length = currentPath.length;
      return currentPath[1] !== 'index' && length > 1 ? currentPath[1] : null;
    }),
    routeParts: computed('currentPath', function () {
      return this.get('currentPath').split('.').filter(function (p) {
        return p !== 'index';
      });
    }),
    routeContexts: computed('currentPath', '_contextMutex', function () {
      var _this = this;

      var parts = this.get('routeParts');
      return parts.map(function (p, i) {
        var dottedNotation = parts.slice(0, i + 1).join('.');
        var route = (0, _emberGetownerPolyfill['default'])(_this).lookup('route:' + dottedNotation);
        var context = undefined;
        if (route && get(route, 'context')) {
          context = get(route, 'context');
        } else {
          context = {};
        }

        return { part: p, path: dottedNotation, context: context };
      });
    }),
    signature: computed('routeContexts', function () {
      return this.get('routeContexts').map(function (part) {
        var context = get(part, 'context');
        if (Object.keys(context).length !== 0) {
          return context[Object.keys(context)[0]];
        } else {
          return get(part, 'part');
        }
      }).join('.');
    }),

    transitionToRoute: function transitionToRoute(name, models, options) {
      return this.get('applicationController').transitionToRoute(name, models, options);
    },

    /**
     * A trigger mechanism for when a route's "dynamic segments" change but not the route itself
     */
    _contextMutex: false,
    refresh: function refresh() {
      this.toggleProperty('_contextMutex');
    },
    _listeners: computed(function () {
      return new _ember['default'].Object();
    })

  });

  navigatorService[_ember['default'].NAME_KEY] = 'ui-navigator';
  exports['default'] = navigatorService;
});