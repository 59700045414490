define('ember-key-manager/utils/combo', ['exports', 'ember', 'ember-key-manager/utils/modifier-keys'], function (exports, _ember, _emberKeyManagerUtilsModifierKeys) {
  var computed = _ember['default'].computed;
  var _get = _ember['default'].get;
  exports['default'] = _ember['default'].Object.extend({
    modifierKeys: computed('keys', {
      get: function get() {
        var keys = _get(this, 'keys');
        return keys.filter(function (k) {
          return _emberKeyManagerUtilsModifierKeys['default'].includes(k);
        });
      }
    }),

    executionKeys: computed('keys', {
      get: function get() {
        var keys = _get(this, 'keys');
        return keys.filter(function (k) {
          return !_emberKeyManagerUtilsModifierKeys['default'].includes(k);
        });
      }
    })
  });
});