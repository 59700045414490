define('ember-data-model-fragments/array/fragment', ['exports', 'ember-data-model-fragments/array/stateful', 'ember-data-model-fragments/fragment', 'ember-data-model-fragments/util/instance-of-type', 'ember-data-model-fragments/util/map'], function (exports, _stateful, _fragment, _instanceOfType, _map) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /**
    @module ember-data-model-fragments
  */

  var get = Ember.get;
  var setProperties = Ember.setProperties;
  var computed = Ember.computed;
  var typeOf = Ember.typeOf;

  // Normalizes an array of object literals or fragments into fragment instances,
  // reusing fragments from a source content array when possible
  function normalizeFragmentArray(array, content, objs, canonical) {
    var record = get(array, 'owner');
    var store = get(record, 'store');
    var declaredModelName = get(array, 'type');
    var options = get(array, 'options');
    var key = get(array, 'name');
    var fragment = void 0;

    return (0, _map.default)(objs, function (data, index) {
      var type = get(array, 'type');
      Ember.assert('You can only add \'' + type + '\' fragments or object literals to this property', typeOf(data) === 'object' || (0, _instanceOfType.default)(store.modelFor(type), data));

      if ((0, _fragment.isFragment)(data)) {
        fragment = data;

        var owner = (0, _fragment.internalModelFor)(fragment)._owner;

        Ember.assert('Fragments can only belong to one owner, try copying instead', !owner || owner === record);

        if (!owner) {
          (0, _fragment.setFragmentOwner)(fragment, record, key);
        }
      } else {
        fragment = content[index];

        if (fragment) {
          // The data could come from a property update, which should leave the
          // fragment in a dirty state, or an adapter operation which should leave
          // it in a clean state
          if (canonical) {
            (0, _fragment.setFragmentData)(fragment, data);
          } else {
            setProperties(fragment, data);
          }
        } else {
          fragment = (0, _fragment.createFragment)(store, declaredModelName, record, key, options, data);
        }
      }

      return fragment;
    });
  }

  /**
    A state-aware array of fragments that is tied to an attribute of a `DS.Model`
    instance. `FragmentArray` instances should not be created directly, instead
    use `MF.fragmentArray` or `MF.array`.
  
    @class FragmentArray
    @namespace MF
    @extends StatefulArray
  */
  var FragmentArray = _stateful.default.extend({
    /**
      The type of fragments the array contains
       @property type
      @private
      @type {String}
    */
    type: null,

    options: null,

    _normalizeData: function _normalizeData(data) {
      var content = get(this, 'content');

      return normalizeFragmentArray(this, content, data, true);
    },
    _createSnapshot: function _createSnapshot() {
      // Snapshot each fragment
      return this.map(function (fragment) {
        return fragment._createSnapshot();
      });
    },
    _flushChangedAttributes: function _flushChangedAttributes() {
      this.map(function (fragment) {
        fragment._flushChangedAttributes();
      });
    },
    _adapterDidCommit: function _adapterDidCommit(data) {
      this._super.apply(this, arguments);

      // Notify all records of commit; if the adapter update did not contain new
      // data, just notify each fragment so it can transition to a clean state
      this.forEach(function (fragment, index) {
        fragment._adapterDidCommit(data && data[index]);
      });
    },
    _adapterDidError: function _adapterDidError(error) {
      this._super.apply(this, arguments);

      // Notify all records of the error; if the adapter update did not contain new
      // data, just notify each fragment so it can transition to a clean state
      this.forEach(function (fragment) {
        fragment._adapterDidError(error);
      });
    },


    /**
      If this property is `true`, either the contents of the array do not match
      its original state, or one or more of the fragments in the array are dirty.
       Example
       ```javascript
      array.toArray(); // [ <Fragment:1>, <Fragment:2> ]
      array.get('hasDirtyAttributes'); // false
      array.get('firstObject').set('prop', 'newValue');
      array.get('hasDirtyAttributes'); // true
      ```
       @property hasDirtyAttributes
      @type {Boolean}
      @readOnly
    */
    hasDirtyAttributes: computed('@each.hasDirtyAttributes', '_originalState', function () {
      return this._super.apply(this, arguments) || this.isAny('hasDirtyAttributes');
    }),

    rollbackAttributes: function rollbackAttributes() {
      this._super.apply(this, arguments);
      this.invoke('rollbackAttributes');
    },
    serialize: function serialize() {
      return this.invoke('serialize');
    },
    replaceContent: function replaceContent(index, amount, objs) {
      var content = get(this, 'content');
      var replacedContent = content.slice(index, index + amount);
      var fragments = normalizeFragmentArray(this, replacedContent, objs);

      return content.replace(index, amount, fragments);
    },
    addFragment: function addFragment(fragment) {
      return this.addObject(fragment);
    },
    removeFragment: function removeFragment(fragment) {
      return this.removeObject(fragment);
    },
    createFragment: function createFragment(props) {
      var record = get(this, 'owner');
      var store = get(record, 'store');
      var type = get(this, 'type');
      var fragment = store.createFragment(type, props);

      return this.pushObject(fragment);
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      // destroy the current state
      this.forEach(function (fragment) {
        fragment.destroy();
      });

      // destroy the original state
      this._originalState.forEach(function (fragment) {
        fragment.destroy();
      });
    }
  });

  exports.default = FragmentArray;
});