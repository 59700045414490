define('ember-time-input/components/time-input', ['exports', 'ember', 'ember-time-input/templates/components/time-input'], function (exports, _ember, _emberTimeInputTemplatesComponentsTimeInput) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var inject = _ember['default'].inject;
  var isPresent = _ember['default'].isPresent;
  exports['default'] = Component.extend({
    moment: inject.service(),
    layout: _emberTimeInputTemplatesComponentsTimeInput['default'],
    classNames: ['time-input'],
    classNameBindings: ['invalid'],

    format: 'hhmm',
    invalid: false,

    inputIsNativeDate: computed('value', function () {
      return this.get('value') instanceof Date;
    }),

    momentDate: computed('value', function () {
      if (isPresent(this.get('value'))) {
        return this.get('moment').moment(this.get('value'));
      }
    }),

    valueString: computed('momentDate', function () {
      var date = this.get('momentDate');
      return date ? date.format(this.get('format')) : '';
    }),

    actions: {
      valueChanged: function valueChanged(valueString) {
        var momentService = this.get('moment');

        var parsed = momentService.moment(valueString, this.get('format'));
        this.set('invalid', !parsed.isValid());
        if (parsed.isValid()) {
          var oldDate = this.get('momentDate');
          var newDate = oldDate ? oldDate.clone() : momentService.moment();
          newDate.hours(parsed.hours());
          newDate.minutes(parsed.minutes());

          if (this.get('inputIsNativeDate')) {
            newDate = newDate.toDate();
          }

          this.set('value', newDate);
          this.sendAction('action', newDate);
        }
      }
    }
  });
});