define('ember-key-manager/services/key-manager', ['exports', 'ember', 'ember-key-manager/utils/key-codes', 'ember-key-manager/utils/modifier-keys', 'ember-key-manager/utils/modifier-key-codes', 'ember-key-manager/utils/combo'], function (exports, _ember, _emberKeyManagerUtilsKeyCodes, _emberKeyManagerUtilsModifierKeys, _emberKeyManagerUtilsModifierKeyCodes, _emberKeyManagerUtilsCombo) {
  var $ = _ember['default'].$;
  var computed = _ember['default'].computed;
  var _get = _ember['default'].get;
  var getOwner = _ember['default'].getOwner;
  var run = _ember['default'].run;
  var set = _ember['default'].set;
  var setProperties = _ember['default'].setProperties;

  var eventNamespace = 'key-manager';
  var inputElements = ['input', 'textarea', 'select', "[contenteditable='true']"];

  exports['default'] = _ember['default'].Service.extend({
    clearExecutionKeysLater: null,
    executionKeyClearInterval: 2000,
    matchFound: false,
    uid: 0,
    altKey: false,
    ctrlKey: false,
    metaKey: false,
    shiftKey: false,

    // config options
    disableOnInput: false,

    init: function init() {
      this._super.apply(this, arguments);
      set(this, 'combos', []);
      this._resetDownKeys();
      this._registerConfig();
    },

    register: function register(_ref) {
      var _this = this;

      var keys = _ref.keys;
      var name = _ref.name;
      var _ref$selector = _ref.selector;
      var selector = _ref$selector === undefined ? $(document) : _ref$selector;
      var downCallback = _ref.downCallback;
      var upCallback = _ref.upCallback;
      var _ref$priority = _ref.priority;
      var priority = _ref$priority === undefined ? 0 : _ref$priority;
      var disableOnInput = _ref.disableOnInput;

      disableOnInput = disableOnInput || _get(this, 'disableOnInput');

      ['up', 'down'].forEach(function (direction) {
        var uid = _get(_this, 'uid');
        var callback = direction === 'up' ? upCallback : downCallback;
        var eventName = 'key' + direction + '.' + eventNamespace + '.' + name + '.' + uid;
        var combo = _emberKeyManagerUtilsCombo['default'].create({
          callback: callback,
          direction: direction,
          eventName: eventName,
          keys: keys,
          name: name,
          selector: selector,
          priority: priority,
          uid: uid,
          disableOnInput: disableOnInput
        });

        _get(_this, 'combos').pushObject(combo);
        selector.on(eventName, {
          eventName: eventName
        }, run.bind(_this, _this.handler));
        _this.incrementProperty('uid');
      });
    },

    deregister: function deregister(_ref2) {
      var name = _ref2.name;

      var combos = _get(this, 'combos');
      var comboMatches = combos.filterBy('name', name);

      comboMatches.forEach(function (comboMatch) {
        var eventName = comboMatch.eventName;
        var selector = comboMatch.selector;

        selector.off(eventName);
        combos.removeObject(comboMatch);
      });
    },

    findMatchingCombo: function findMatchingCombo(event) {
      var _this2 = this;

      try {
        var data = event.data;
        var keyCode = event.keyCode;
        var _data$eventName = data.eventName;
        var eventName = _data$eventName === undefined ? null : _data$eventName;

        _emberKeyManagerUtilsModifierKeys['default'].forEach(function (key) {
          var prop = key + 'Key';
          set(_this2, prop, event[prop]);
        });

        if (!_emberKeyManagerUtilsModifierKeyCodes['default'].includes(keyCode)) {
          _get(this, 'downKeys').addObject(keyCode);
        }

        return this._findComboByName(eventName);
      } finally {
        this._clearExecutionKeys(event);
      }
    },

    handler: function handler(event) {
      var _this3 = this;

      if (_get(this, 'isDestroyed') || _get(this, 'isDestroying')) {
        return;
      }

      var combo = this.findMatchingCombo(event);
      var runLoopGuard = !_get(this, 'matchFound');

      if (combo && runLoopGuard) {
        set(this, 'matchFound', true);
        run.next(function () {
          set(_this3, 'matchFound', false);
        });

        var isNotOnInput = inputElements.every(function (e) {
          return !$(document.activeElement).is(e);
        });
        if (!_get(combo, 'disableOnInput') || isNotOnInput) {
          var callback = _get(combo, 'callback');
          if (callback) {
            callback(event);
          }
        }
      }
    },

    executionKeys: computed(function () {
      return Object.keys(_emberKeyManagerUtilsKeyCodes['default']).map(function (key) {
        return _emberKeyManagerUtilsKeyCodes['default'][key];
      }).reject(function (code) {
        return _emberKeyManagerUtilsModifierKeyCodes['default'].includes(code);
      });
    }),

    _clearExecutionKeys: function _clearExecutionKeys(event) {
      if (event.type === 'keyup') {
        _get(this, 'downKeys').removeObject(event.keyCode);
      }

      _get(this, 'downKeys').removeObjects(this.get('executionKeys'));
    },

    _findComboByName: function _findComboByName(eventName) {
      var combos = _get(this, 'combos');
      var comboWithName = combos.findBy('eventName', eventName);

      if (!this.isComboKeyMatch(comboWithName)) {
        return;
      }

      var highestPriorityCombo = this._combosWithKeys(combos).sortBy('priority').get('lastObject');

      // Matching Event is Combo with Highest Priority
      if (_get(comboWithName, 'priority') >= _get(highestPriorityCombo, 'priority')) {
        return comboWithName;
      }
    },

    _combosWithKeys: function _combosWithKeys(combos) {
      var _this4 = this;

      return combos.filter(function (c) {
        return _this4.isComboKeyMatch(c);
      });
    },

    pressedModifiers: computed('altKey', 'ctrlKey', 'metaKey', 'shiftKey', {
      get: function get() {
        var _this5 = this;

        return _emberKeyManagerUtilsModifierKeys['default'].filter(function (key) {
          return _get(_this5, key + 'Key');
        });
      }
    }),

    isComboKeyMatch: function isComboKeyMatch(combo) {
      if (!combo) {
        return;
      }

      var downKeys = _get(this, 'downKeys');
      var pressedModifiers = _get(this, 'pressedModifiers');

      var comboKeys = _get(combo, 'keys');
      var comboModifierKeys = _get(combo, 'modifierKeys');
      var comboExecutionKeys = _get(combo, 'executionKeys');

      return comboKeys.length === pressedModifiers.length + downKeys.length &&
      // Filter Combos with Matching Modifier Keys
      comboModifierKeys.every(function (k) {
        return pressedModifiers.includes(k);
      }) &&
      // Filter Combos with Matching Execution Keys
      comboExecutionKeys.every(function (k) {
        return downKeys.includes(_emberKeyManagerUtilsKeyCodes['default'][k]);
      });
    },

    _resetDownKeys: function _resetDownKeys() {
      set(this, 'downKeys', []);
    },

    _registerConfig: function _registerConfig() {
      var config = getOwner(this).lookup('main:key-manager-config');
      if (config) {
        setProperties(this, config);
      }
    }
  });
});